<template>
    <div v-if="isShow" class="m-wrapper08 position-wrapper" @click="closePositionWrapper">
        <div class="area-top-con01">
            <img src="../../assets/images/icon-wz.png" class="area-icon01">
            <span class="f-fl">当前位置：</span>
            <a class="city01">{{currentChooseCity}}</a>
        </div>
        <ul class="area-list01">
            <li v-for="city in cityList" :key="city.id">
                <a @click="changeCity(city.id)" class="area01" :class="currentCity === city.id ? 'area-selected' : ''">
                    <span>{{city.text}}</span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: ['isShow', 'currentChooseCity'],
    data() {
        return {
            cityList: [{
                id: 1,
                text: '福州'
            },{
                id: 2,
                text: '厦门'
            },{
                id: 3,
                text: '宁德'
            },{
                id: 4,
                text: '莆田'
            },{
                id: 5,
                text: '泉州'
            },{
                id: 6,
                text: '漳州'
            },{
                id: 7,
                text: '龙岩'
            },{
                id: 8,
                text: '三明'
            },{
                id: 9,
                text: '南平'
            }]
        }
    },
    computed: {
        currentCity() {
            let res = this.cityList.filter(item => {
                return item.text === this.currentChooseCity
            })
            return res[0]['id'] || 1
        }
    },
    methods: {
        closePositionWrapper() {
            this.$emit('update:isShow', false)
        },
        changeCity(id) {
            this.$emit('update:currentChooseCity', this.cityList[id - 1]['text'])
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.position-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0 !important;

    .city01 {
        color: $mainColor;
    }
}
</style>
