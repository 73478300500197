<template>
    <div v-if="isShowSearch" class="m-wrapper08" id="index-search">
        <div class="m-search01">
            <a @click="returnSelectHospital" class="return-icon01" id="return01"></a>
            <input type="text" v-model="searchVal" placeholder="请输入医院" class="search02">
            <a @click="search" class="search-btn01">搜索</a>
        </div>
        <!-- 显示搜索到的医院列表 -->
        <div v-if="showHospitalList">
            <div class="date01">
                为您找到{{hospitalList.length}}家医院
            </div>
            <!-- 搜索到的医院信息 -->
            <hospital-item :hospitalList="hospitalList"></hospital-item>
        </div>
        <!-- 初始搜索页面提示语 -->
        <div v-else class="m-search-default01">
            <div class="search-tit01">您可以搜索</div>
            <div class="search-default-con01">
                <div class="search-type01">
                    <img src="../../assets/images/icon-yy.png">
                    <span>医院</span>
                </div>
            </div>
        </div>
        <div class="m-search-default02" style="display: none;">
            <ul class="search-default-con02">
                <li>
                    <a href="search-result.html">附属</a>
                </li>
                <li>
                    <a href="search-result.html">省立</a>
                </li>
            </ul>
            <a href="javascript:;" class="delete-link01">
                <img src="../../assets/images/icon-sc.png" class="delete-icon01">清空全部历史记录
            </a>
        </div>
    </div>
</template>
<script>
import HospitalItem from './hospitalItem'
export default {
    props: ['isShowSearch', 'hospitalList'],
    data() {
        return {
            showHospitalList: false, //搜索页面默认隐藏医院列表信息
            searchedHospitalList: [],
            searchVal: ''
        }
    },
    components: {
        HospitalItem
    },
    methods: {
        search() {
            // 请求搜索医院
            this.$emit('getHospitalList', this.searchVal)
            this.showHospitalList = true;
            // 否则显示没有查询到相关医院信息
        },
        returnSelectHospital() {
            // 关闭当前页面， 显示选择医院界面
            this.$emit('update:isShowSearch', false)
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.search-btn01  {
    background: $mainColor;
}
</style>