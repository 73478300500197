<template>
    <ul class="m-hospital01">
        <li class="hospital-con01 hospital-item" v-for="item in hospitalList" :key="item.name">
            <a @click="intoHospital(item)">
                <img src="../../assets/images/pic-10.png" class="hospital-pic01">
                <div class="hospital-box01">
                    <h5 class="hostpital-info01">{{item.hosName}}</h5>
                    <div class="hostpital-info02">
                        <span class="red">{{item.levelName}}</span>
                    </div>
                    <div class="hostpital-info03">{{item.address}}</div>
                </div>
            </a>
        </li>
    </ul>
</template>
<script>
import store from '@/store'
export default {
    store,
    props: ['hospitalList'],
    data() {
        return {

        }
    },
    methods: {
        intoHospital(item) {
            console.log('item,', item)
            // 如果是从添加就诊卡--选择医院转过来的from=choose，只是反馈医院名
            let from = this.$route.query['from']
            if(from === 'choose') {
                // 从添加医院进来的页面 选择医院之后需要将信息返显在addCard页面上
               this.$store.commit('setChoosedHospital', item.hospitalName) 
               this.$router.push('/addCard/1?from=add')
               return;
            }
            // 进入医院
            this.$router.push(`/selectDepartment?cityCode=${item.cityCode}&name=${item.hospitalName}&id=${item.hospitalId}`)
        }
    }
}
</script>
<style lang="scss" scoped>

</style>