<template>
    <div class="position-container">
        <!-- 默认搜索页面的医院列表 -->
        <div v-if="!isShowSearch" class="m-wrapper08" id="index-default">
            <div v-if="from == 'choose'" class="m-search01">
                <a @click="searchHospital" class="search01" id="search-link01">搜索医院</a>
                <a @click="showPositionChoose" class="m-loacation01" :class="from == 'choose' ? 'choose-hospital' : ''">
                    <span class="position">{{currentChooseCity}}</span>
                </a>
            </div>
            <div v-else class="m-search01">
                <a @click="showPositionChoose" class="m-loacation01">
                    <span class="position">{{currentChooseCity}}</span>
                </a>
                <a @click="searchHospital" class="search01" id="search-link01">搜索医院</a>
            </div>
            <!-- 医院 -->
            <hospital-item :hospitalList="hospitalList"></hospital-item>
        </div>
        <!-- 选择当前所处地址 -->
        <position-choose :isShow.sync="isShow" :currentChooseCity.sync="currentChooseCity"></position-choose>
        <!-- 搜索医院 -->
        <select-hospital @getHospitalList="getHospitalList" :isShowSearch.sync="isShowSearch" :hospitalList="hospitalList"></select-hospital>
    </div>
</template>
<script>
import HospitalItem from './hospitalItem'
import PositionChoose from './positionChoose'
import SelectHospital from './selectHospital'
import {mapState} from 'vuex'
import {cityCodeMapping} from '@/constant/constant'
export default {
    data() {
        return {
            currentChooseCity: '福州',
            isShow: false, // 是否显示选择地址
            isShowSearch: false, // 是否显示搜索页面
            showDefaultList: true, //是否显示默认的医院列表
            from: ''
        }
    },
    components: {
        PositionChoose,
        SelectHospital,
        HospitalItem
    },
    computed: {
        ...mapState({
            hospitalList: state => state.offlineApply.hospitalList
        })
    },
    mounted() {
        this.from = this.$route.query['from']
        this.getHospitalList() //获取医院列表
        console.log('hospitalList,', this.hospitalList)
    },
    methods: {
        getHospitalList(hosName) {
            // 获取医院列表信息
            this.$store.dispatch('getHospitalList', {
                cityCode: cityCodeMapping[this.currentChooseCity],
                pageIndex: 1,
                hospitalName: hosName
            })
        },
        showPositionChoose() {
            this.isShow = true;
        },
        searchHospital() {
            this.isShowSearch = true;
            // this.showDefaultList = false;
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.m-wrapper08 {
    text-align: left;
}
.hospital-item,.search01 {
    text-align: left;
}

.position-container {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
}

.area-list01 {
    text-align: left !important;
}

.position {
    color: #666;
}

.m-loacation01 {
    &.choose-hospital {
        span {
            background: none;
        }
    }
}
</style>